import moment from 'moment'
import axios from 'axios'
import {orderBy, fromPairs} from 'lodash'

import {makeSetters} from '@helpers/vuex/mutations'
import {smarterGet} from '@helpers/vuex/data-loading'

const defaults = () => ({
  mode: null,
  showModeDetails: false,
  harvestYearOutdated: false,
  supportedCropUsageIdsForAuto: [],
  borderGeometry: null,
  cultivations: [],
  selectedQuickCultivations: [],
  notes: '',
  automaticModeUseLandsat: false,
  existingYearsWithFieldHistory: [],
  cloudCoverageThreshold: 0.05,
  buffer: 10,
  useRedBorder: true
})

export default {
  namespaced: true,
  state: defaults(),
  getters: {
    selectedCropUsageRanges (state, getters) {
      return getters.relevantCultivations.map(({cropUsageId, dateTimeRange}) => ({cropUsageId, dateTimeRange}))
    },
    relevantYears (state) {
      const now = moment().year()

      const years = state.mode === 'premium' ? 10 : now - 2015 + 1
      return new Array(years).fill(null).map((x, i) => now - i)
    },
    relevantCultivations (state, getters) {
      const overlapsValidYear = cultivation => cultivation.harvestYears.some(year => getters.relevantYears.includes(year))

      return state.cultivations.filter(overlapsValidYear).filter(cult => cult.type === 'main')
    },
    supportedCultivations (state, getters) {
      if (state.mode === 'premium') return getters.relevantCultivations

      const isSupported = cultivation => cultivation.cropUsageId && state.supportedCropUsageIdsForAuto.includes(cultivation.cropUsageId)

      return getters.relevantCultivations.filter(isSupported)
    },
    cultivationsByYear (state, getters) {
      return fromPairs(getters.relevantYears.map(year => [
        year,
        orderBy(getters.relevantCultivations.filter(cultivation => cultivation.harvestYears.includes(year)), ['dateTimeRange.start'], ['desc'])
      ]))
    },
    yearsWithoutCultivation (state, getters) {
      return getters.relevantYears.filter(year => getters.cultivationsByYear[year].length === 0)
    },
    quickCultivations (state) {
      return orderBy(state.cultivations.filter(cult => cult.isQuickCultivation), ['dateTimeRange.start'], ['desc'])
    }
  },
  actions: {
    async loadPrerequisiteData ({commit, state, rootGetters, rootState}) {
      const {fieldId} = rootState.fieldRecordSystem.navigation.location
      const {harvestYear} = rootState.fieldRecordSystem.userSettings
      commit('reset')

      const fieldSpecificInputs = {
        expiry: 0, // NOTE does not work properly with high expiration because store location is unloaded on module exit
        inputs: {
          harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear,
          fieldId: () => rootGetters['fieldRecordSystem/fieldId']
        }
      }

      await Promise.all([
        axios.get(`/api/v2/frs/${harvestYear}/fields/${fieldId}/is-newest`)
          .catch(() => {
            commit('setHarvestYearOutdated', true)
          }),
        smarterGet('/api/v2/zone-maps/automatic/supported-crop-usages', {
          id: 'automaticZoneMaps.supportedCropUsages',
          expiry: 0, // NOTE does not work properly with high expiration because store location is unloaded on module exit
          onResult (supportedCropUsageIdsForAuto) {
            commit('setSupportedCropUsageIdsForAuto', supportedCropUsageIdsForAuto)
          }
        }),
        smarterGet('/api/v2/zone-maps/automatic/{harvestYear}/fields/{fieldId}/existing-field-history-for-last-decade', {
          id: 'automaticZoneMaps.setExistingYearsWithFieldHistory',
          ...fieldSpecificInputs,
          onResult (existingYearsWithFieldHistory) {
            commit('setExistingYearsWithFieldHistory', existingYearsWithFieldHistory)
          }
        }),
        smarterGet('/api/v2/entities/fields/{fieldId}/geodata/{harvestYear}', {
          id: 'automaticZoneMaps.borderGeometry',
          ...fieldSpecificInputs,
          onResult (geometry) {
            commit('setBorderGeometry', geometry)
          }
        })
      ])

      await smarterGet('/api/v2/frs/{harvestYear}/fields/{fieldId}/cultivations/all', {
        id: 'automaticZoneMaps.cultivations',
        sharedCache: true,
        expiry: 0,
        inputs: {
          harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear,
          fieldId: () => rootGetters['fieldRecordSystem/fieldId']
        },
        onResult (cultivations) {
          cultivations.forEach(cult => {
            const isQuickCult = !cult.harvestYears.some(year => state.existingYearsWithFieldHistory.includes(year))

            if (isQuickCult) {
              cult.id = `cultivation_${cult.harvestYears[0]}`
            }
            cult.isQuickCultivation = isQuickCult
          })
          commit('setCultivations', cultivations)
        }
      })
    },
    startCreation ({state, getters, rootState, rootGetters}) {
      const cultivationInfo = getters.supportedCultivations.map(({cropUsageId, dateTimeRange, harvestYears}) => ({
        cropUsageId,
        startTime: dateTimeRange.start,
        endTime: dateTimeRange.end,
        harvestYear: harvestYears[0]
      }))
      const {fieldId, orgUnitId} = rootState.fieldRecordSystem.navigation.location
      const {harvestYear} = rootState.fieldRecordSystem.userSettings
      let billingOrgUnitId = orgUnitId

      const paymentData = rootGetters['fieldRecordSystem/companyData/nextPaymentData']
      if (paymentData) {
        billingOrgUnitId = paymentData.orgUnitId
      }

      return axios.post(`/api/v2/zone-maps/${state.mode}`, {
        cultivationInfo: orderBy(cultivationInfo, ['harvestYear'], ['desc']),
        orgUnitId,
        geometry: state.borderGeometry,
        billingOrgUnitId,
        fieldHistoryLocation: {
          fieldId,
          harvestYear
        },
        notes: state.notes,
        automaticModeUseLandsat: state.automaticModeUseLandsat,
        cloudCoverageThreshold: state.cloudCoverageThreshold,
        buffer: state.useRedBorder ? state.buffer : null
      })
    },
    createQuickCultivations ({state, dispatch}) {
      return Promise.all(state.selectedQuickCultivations.map(cult => {
        return dispatch('fieldRecordSystem/cultivationPlanning/saveQuickCultivation', {year: cult.harvestYears[0], cropUsageId: cult.cropUsageId}, {root: true})
      }))
    }
  },
  mutations: {
    ...makeSetters([
      'showModeDetails',
      'cultivations',
      'mode',
      'harvestYearOutdated',
      'supportedCropUsageIdsForAuto',
      'notes',
      'automaticModeUseLandsat',
      'existingYearsWithFieldHistory',
      'cloudCoverageThreshold',
      'buffer',
      'useRedBorder',
      'borderGeometry'
    ]),
    updateSelectedQuickCultivations (state, cultivations) {
      state.selectedQuickCultivations = cultivations
    },
    upsertQuickCultivation (state, {cropUsageId, year}) {
      if (state.mode === 'premium' || state.supportedCropUsageIdsForAuto.includes(cropUsageId)) {
        const cultivation = {
          id: `cultivation_${year}`,
          harvestYears: [year],
          type: 'main',
          dateTimeRange: {
            start: moment([year]).startOf('year').toISOString(),
            end: moment([year]).endOf('year').toISOString()
          },
          isQuickCultivation: true,
          cropUsageId
        }
        const selectedQuickCultivations = state.selectedQuickCultivations
        const cultivations = state.cultivations
        const indexQuickCultivation = selectedQuickCultivations.map(x => x.id).indexOf(cultivation.id)
        const index = cultivations.map(x => x.id).indexOf(cultivation.id)

        if (indexQuickCultivation > -1) {
          selectedQuickCultivations.splice(indexQuickCultivation, 1)
        }
        cultivations.push(cultivation)
        state.cultivations = cultivations

        if (index > -1) {
          cultivations.splice(index, 1)
        }

        if (state.existingYearsWithFieldHistory.includes(year)) {
          selectedQuickCultivations.push(cultivation)
        }
        state.selectedQuickCultivations = selectedQuickCultivations
      } else {
        console.error('No supported CropUsageId:' + cropUsageId)
      }
    },
    deleteQuickCultivation (state, year) {
      const cultivations = state.cultivations
      cultivations.splice(cultivations.map(x => x.id).indexOf(`cultivation_${year}`), 1)
      state.cultivations = cultivations
    },
    reset (state) {
      Object.assign(state, defaults())
    },
    quickCultivationsSelection (state, allSelected) {
      if (allSelected) {
        state.selectedQuickCultivations = orderBy(state.cultivations.filter(cult => cult.isQuickCultivation && state.existingYearsWithFieldHistory.includes(cult.harvestYears[0])), ['dateTimeRange.start'], ['desc'])
      } else {
        state.selectedQuickCultivations = []
      }
    }
  }
}
